import { IRoute } from '@app/data/schema/route';
/*

This file will be a common lookup for paths, permissions and names for the route which will be used to refer in html and ts
 if we need to change an existing route only need to edit this file

*/
export let ROUTES: Record<string, IRoute> = {
    home: {
        icon: '',
        name: '',
        path: '',
        permissions: [],
        breadcrumb: [

        ]
    },
    projects: {
        icon: '',
        name: 'projects',
        path: 'projects',
        permissions: [],
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                active: true,
                terminal:false,
                visible:true,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Programs',
                name: 'Programs',
                active: false,
                visible:true,
                terminal:false,
                route: '/dashboard/program',
                url: ''
            },
            {
                label: 'Project List',
                name: 'Project List',
                active: false,
                visible:true,
                terminal:true,
                route: '/dashboard/program/projects',
                url: ''
            }
        ]
    },
    programs: {
        icon: '',
        name: 'programs',
        path: 'programs',
        permissions: [],
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                terminal:false,
                visible:true,
                active: true,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Programs',
                name: 'Programs',
                terminal:true,
                visible:true,
                active: false,
                route: '/dashboard/programs',
                url: ''
            }
        ]
    },
    dataSources: {
        icon: '',
        name: 'dataSources',
        path: 'data-sources',
        permissions: [],
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                active: true,
                visible:true,
                terminal:false,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'dataSources',
                name: 'dataSources',
                active: false,
                visible:true,
                terminal:true,
                route: '/dashboard/data-sources',
                url: ''
            }
        ]
    },
    programProjects: {
        icon: '',
        name: 'project-details',
        path: 'program/:id/projects',
        getPath: (id)=>{
            return '/dashboard/program/'+id+'/projects'
        },
        permissions: [],
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                terminal:false,
                visible:true,
                active: true,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Programs',
                name: 'Programs',
                terminal:false,
                visible:true,
                active: false,
                route: '/dashboard/programs',
                url: ''
            },
            {
                label: 'Project List',
                name: 'Project List',
                terminal:true,
                visible:true,
                active: false,
                route: '/dashboard/program/projects',
                url: ''
            }
        ]
    },
    programReporting: {
        icon: '',
        name: 'program-reporting',
        path: 'program/:id/reporting',
        getPath: (id)=>{
            return '/dashboard/program/'+id+'/reporting'
        },
        permissions: [],
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                terminal:false,
                visible:true,
                active: true,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Programs',
                name: 'Programs',
                terminal:false,
                visible:true,
                active: false,
                route: '/dashboard/programs',
                url: ''
            },
            {
                label: 'Project List',
                name: 'Project List',
                terminal:true,
                visible:true,
                active: false,
                route: '/dashboard/program/projects',
                url: ''
            }
        ]
    },
    success:{
        icon: '',
        name: 'success',
        path: 'success',
        getPath: (id)=>{
            return '/dashboard/success'
        },
        setConfig:(data)=>{
         ROUTES.success.config=data;
        },
        permissions: [],
        breadcrumb: [],
        config:{}
    },
    projectDetails: {
        icon: '',
        name: 'projects',
        path: 'projects/:id/details',
        permissions: [],
        getPath:(id)=>{
            return '/dashboard/projects/'+id+'/details'
        },
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                active: true,
                visible:true,
                terminal:false,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Program 1',
                name: 'Programs',
                active: true,
                visible:true,
                terminal:false,
                route: '/dashboard/programs',
                url: ''
            },
            {
                label: '',
                name: 'Project1',
                active: true,
                terminal:true,
                visible: true,
                route: '/dashboard/program/projects',
                url: ''
            },
            {
                label: '',
                name: 'Project2',
                active: true,
                visible: false,
                terminal:true,
                route: '/dashboard/program/projects',
                url: ''
            },
            {
                label: '',
                name: 'Project3',
                active: false,
                visible: false,
                terminal:true,
                route: '/dashboard/projects/details',                
                url: ''
            }
        ]
    },
    projectCreate: {
        icon: '',
        name: '',
        path: 'program/:id/project/create',
        permissions: [],
        getPath:(id)=>{
            return `/dashboard/program/${id}/project/create`
        },
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                active: true,
                terminal:false,
                visible:true,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Programs',
                name: 'Programs',
                active: true,
                terminal:false,
                visible:true,
                route: '/dashboard/projects',
                url: ''
            },
            {
                label: 'Create New Project',
                name: 'Create',
                active: false,
                terminal:true,
                visible:true,
                route: '/dashboard/program/projects',
                url: ''
            }
        ]
    },
    projectEdit: {
        icon: '',
        name: '',
        path: 'program/:id/project/:projectId/edit',
        permissions: [],
        getPath:(id,projectId)=>{
            return `/dashboard/program/${id}/project/${projectId}/edit`
        },
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                active: true,
                terminal:false,
                visible:true,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Programs',
                name: 'Programs',
                active: true,
                terminal:false,
                visible:true,
                route: '/dashboard/projects',
                url: ''
            },
            {
                label: '',
                name: 'Project1',
                active: true,
                terminal:true,
                visible: true,
                route: '/dashboard/program/projects',
                url: ''
            },
            {
                label: '',
                name: 'Project2',
                active: true,
                visible: false,
                terminal:true,
                route: '/dashboard/program/projects',
                url: ''
            },
            {
                label: '',
                name: 'Project3',
                active: false,
                visible: false,
                terminal: false,
                route: '/dashboard/projects/details',                
                url: ''
            },
            {
                label: 'Edit Project',
                name: 'Edit',
                active: false,
                terminal:true,
                visible:true,
                route: '/dashboard/program/projects',
                url: ''
            }
        ]
    },
    manageRoles: {
        icon: '',
        name: '',
        path: 'manage-roles',
        permissions: [],
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                active: true,
                terminal:false,
                visible:true,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Manage Roles',
                name: 'Manage Roles',
                active: false,
                visible:true,
                terminal:true,
                route: '/dashboard/program/projects',
                url: ''
            }
        ]
    },
    childProjectCreate: {
        icon: '',
        name: '',
        path: 'program/:id/project/:parentProjectId/child/create',
        permissions: [],
        getPath:(id, parentProjectId)=>{
            return `/dashboard/program/${id}/project/${parentProjectId}/child/create`
        },
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                active: true,
                visible:true,
                terminal:false,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Programs',
                name: 'Programs',
                active: true,
                terminal:false,
                visible:true,
                route: '/dashboard/projects',
                url: ''
            },
            {
                label: '',
                name: 'Project1',
                active: true,
                terminal:true,
                visible: true,
                route: '/dashboard/program/projects',
                url: ''
            },
            {
                label: '',
                name: 'Project2',
                active: true,
                visible: false,
                terminal:true,
                route: '/dashboard/program/projects',
                url: ''
            },
            {
                label: '',
                name: 'Project3',
                active: false,
                visible: false,
                terminal: false,
                route: '/dashboard/projects/details',                
                url: ''
            },
            {
                label: 'Create New Project',
                name: 'Create',
                active: false,
                terminal:true,
                visible:true,
                route: '/dashboard/program/projects',
                url: ''
            }
        ]
    },
    manageReporting: {
        icon: '',
        name: '',
        path: 'manage-reporting',
        permissions: [],
        breadcrumb: [
            {
                label: 'Home',
                name: 'Home',
                active: true,
                terminal:false,
                visible:true,
                route: '/dashboard',
                url: ''
            },
            {
                label: 'Manage Reporting',
                name: 'Manage Reporting',
                active: false,
                visible:true,
                terminal:true,
                route: '',
                url: ''
            }
        ]
    },
    
};
