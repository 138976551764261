import { environment } from "@env";

export interface IStorageService {
  storeItem(item: any, key: Storagekeys): void;
  getItem(key: Storagekeys): any;
}
export enum Storagekeys {
  token = 'YbHvhvznsdhbzopi8hdsb23=',
  program = 'Uiomkmnsdhtgkokpi8hdsb23=',
  peoplePickerToken = 'Miomkmnsdhgponrdxdxdx5dsb23=',
  user = 'XUh9ofgmnsdht34Dpi8opsb23=',
  project= 'Fghhyyiui465fbiuuonqyyyee',
  role= 'Tysdsd09jkjui465fbiurtnfgyee',
  notification = "Tysdsd09jkjui465fbiurtnfgyee1",
  graphToken = "Tysdsd09jkjui465fbiurtnfgyee2"
};
//msal.3e571461-7d57-4b35-9ac5-8d44cb998e1c.active-account
export interface StorageItem {
  key: Storagekeys;
  value: any;
};
