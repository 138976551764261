import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthGuard } from './guard/auth.guard';
import { NoAuthGuard } from './guard/no-auth.guard';
import { ProgramGuard } from './guard/program.guard';
import { ProjectGuard } from './guard/project.guard';
import { throwIfAlreadyLoaded } from './guard/module-import.guard';
import { DataSourceGuard } from './guard/datasource.guard';
@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthGuard,
    NoAuthGuard,
    ProgramGuard,
    ProjectGuard,
    DataSourceGuard
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
