import { Inject, Injectable } from '@angular/core';
import { Storagekeys } from '@app/shared/constants/storage';
import { ApiService } from '@app/shared/service/api.service';
import { StorageService } from '@app/shared/service/storage.service';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API_URLS } from '../constants/api-url';
import { CookieService } from 'ngx-cookie';

interface LoginContextInterface {
  username: string;
  password: string;
  token: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private _ApiService: ApiService,private _CookieService:CookieService, private storage: StorageService, private _MsalService: MsalService,@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) { }
  getOIDCUserInfo() {
    return this._ApiService.doGet(API_URLS.getOIDCUserInfo, { loaderVisible: false })
  }
  getProfile(imgUrl) {
    return this._ApiService.doGetBlob(imgUrl, { loaderVisible: false })
  }
  // getUserInfo(email) {
  //   let url = API_URLS.getUserInfo + `/${email}`;
  //   return this._ApiService.doGet(url, { loaderVisible: true })
  // }
  isTokenExpired(token: String | null) {
    if (token) {
      var expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
      return new Date(expiry * 1000) <= new Date();
    }
    return false;
  }
  fetchPeoplePickerToken(): any {
    return this._ApiService.doGet(environment.peoplePickerUrl).pipe(map((res) => {
      if (res) {
        this.storage.storeItem(Storagekeys.peoplePickerToken, res.accessToken);
      }
      return res;
    }), catchError((err: any) => {
      throw err;
    }));
  }
  public getTokenSilent(): Observable<AuthenticationResult> {
    return this._MsalService.acquireTokenSilent(
      { scopes: environment.peoplePickerScope, account: this._MsalService.instance.getAllAccounts()[0] })
      .pipe(map((x) => {
        if (this.isTokenExpired(x.idToken)) {
          this._CookieService.removeAll();
          window.localStorage.clear();
          window.sessionStorage.clear();
          this.login();
        }
        let dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 4);
        this._CookieService.put('FMB_API', x.idToken,{ expires: dateNow, secure: true, sameSite: 'lax' });
          this.storage.storeItem(Storagekeys.token, x.idToken)
        return x;
      }), catchError((err: any) => {
        console.log(err, 'login error occured');
        this._CookieService.removeAll()
        window.localStorage.clear();
        window.sessionStorage.clear();
        this.login();
        //}

        throw err;
      })
      );
  }
  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this._MsalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this._MsalService.instance.setActiveAccount(response.account);
          });
      } else {
        this._MsalService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this._MsalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this._MsalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this._MsalService.loginRedirect();
      }
    }
  }
}
