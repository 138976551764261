import { environment } from '../../../environments/environment';
export const BASE_URL = environment.serverUrl;
export const PEOPLE_PICKER_BASE_URL = environment.peoplePickerUrl;
export const API_URLS = {
    getProgram: BASE_URL + '/Program/getPrograms',
    getDashboardPrograms:BASE_URL + '/Program/getDashboardPrograms',
    getnotifications: BASE_URL + '/Notification/getnotifications',
    notificationRolelist: BASE_URL + '/RBAC/rbacList',
    getnotification: BASE_URL + '/Notification/getnotification',
    // getProgramById: BASE_URL + '/Program',
    // getProgramById: 'https://620f5d37ec8b2ee2833cfc40.mockapi.io/program',
    getCustomDatasources: BASE_URL+'/Project/CustomFields',
    getColumnValues: BASE_URL+'/Project/GetColumnValues',
    // getDatasources: 'https://620f5d37ec8b2ee2833cfc40.mockapi.io/datasource',
    getDatasources: BASE_URL+'/DataSource',
    getDataSourceByProgramAsync: BASE_URL+'/DataSource/GetDataSourceByProgramAsync',
    getDatasourcesByUser: BASE_URL+'/DataSource/getDataSourceByUser',
    deleteDatasources: BASE_URL+'/DataSource/DeleteDataSource',
    getDatasourceDetails: BASE_URL+'/DataSource',
    updateDatasources: BASE_URL+'/DataSource',
    saveDatasource: BASE_URL+'/DataSource/savejob',
    saveAPIJobDatasource: BASE_URL+'/DataSource/saveapijob',
    validateQuery: BASE_URL+'/DataSource/validatejobquery',
    validateApiJob:BASE_URL+'/DataSource/validateapijob',
    addNewDataSource: BASE_URL+'/Project/ProcessingFiles',
    importProjectDetails: BASE_URL+'/Project/ImportProjectDetails',
    getDocHistory: BASE_URL+'/Project/getdochistory',
    getProjectDetails: BASE_URL+'/Project/ProjectDetails',
    getProjectsByProgram: BASE_URL+'/Project/getProjectsByProgramId',
    // getDatasourceFields: 'https://620f5d37ec8b2ee2833cfc40.mockapi.io/datasourceFields',
    getDatasourceFields: BASE_URL+'/DataSource/GetDataSourceFieldAsync',
    getDatasourceValues:  BASE_URL+'/DataSource/GetDataSourceFieldData',
    getCustomValues:  BASE_URL+'/Custom/GetCustomFieldData',
    getProgramById: BASE_URL + '/Program/GetProgramByIdAsync',
    getNotification: BASE_URL + '/Notification/notifications',
    getProjects: BASE_URL + '/Project/getProjectAsync',
    createProject: BASE_URL + '/Project',
    editProject: BASE_URL + '/Project/UpdateProjectDetails',
    getChildProjects: BASE_URL + '/Project/Child',
    deleteProject: BASE_URL + '/Project/Update',
    getProjectData: BASE_URL+'/Project/ProjectViewDetails',
    getProjectFields: BASE_URL+'/AZ/People/search',
    projectById: BASE_URL+'/Project/projectById',
    getPresigned: BASE_URL+'/Project/GetPreSignedUrlUpload',
    getPresignedExport: BASE_URL+'/Project/GetPreSignedUrlExport',
    // getUserInfo: BASE_URL + '/User',
    addProgram: BASE_URL + '/Program', 
    addNotification: BASE_URL + '/Notification', 
    addNotifications: BASE_URL + '/Notification/NotificationSave',
    editProgram: BASE_URL + '/Program/UpdateProgram',
    // getProgram: 'https://620f5d37ec8b2ee2833cfc40.mockapi.io/program',
    // getProjects : 'https://620f5d37ec8b2ee2833cfc40.mockapi.io/projects',
    getOIDCUserInfo: 'https://graph.microsoft.com/oidc/userinfo',
    searchPeople: PEOPLE_PICKER_BASE_URL+'/AZ/people/Search',
    searchDataSource: BASE_URL+'/DataSource/GetDataSourceBySearchTextAsync',
    deleteProgram: BASE_URL + '/Program/Delete',
    deleteNotification: BASE_URL + '/Notification/Delete',
    updateProjectDetails: BASE_URL + '/MongoDataSource',
    getUserList: BASE_URL + '/Project/UserName',
    getUserPermissions: BASE_URL + '/RBAC/rbacList',
    UpdateRolePermissions: BASE_URL + '/RBAC/UpdateRolePermissions',
    getPermissions: BASE_URL + '/RBAC/rbac',
    exportProject: BASE_URL + '/Project/ExportProjectDetails',
    getOrganizationData: BASE_URL + '/Project/getOrganizationData',
    updateManageReporting: BASE_URL + '/Program/UpdateManageReportingRequest',    
    createQuickSightDataset: BASE_URL + '/QuickSight/CreateDataSet',
    viewQuickSight: BASE_URL + '/QuickSight/GetEmbedURL',
    getProjectActivities: BASE_URL +'/MongoDataSource/project-activities',
    documentSave: BASE_URL + '/Document/SaveDoument',
    documnetGet:BASE_URL+ '/Document/GetDocumentsData',
    documentGetPreSignedUrlUpload:BASE_URL +'/Document/GetPreSignedUrlUpload',
    documentGetPreSignedUrl:BASE_URL +'/Document/GetPreSignedUrl',
    documentDelete:BASE_URL +  '/Document/DeleteDocument',
    migrationGetPreSignedUrl :BASE_URL + '/api/Migration/GetPreSignedUrlUpload',
    migrationImport:BASE_URL + '/api/Migration/ImportMigration',
    migrationGetMigrationUploadHistory:BASE_URL +'/api/Migration/GetMigrationUploadHistory',
    getQuestionnaireData:BASE_URL+'/Questionnaire/GetData',
    getQuestionnaireHeader:BASE_URL+'/Questionnaire/GetHeader',
    getQuestionnaireSearchHeader:BASE_URL+'/Questionnaire/GetLandingPage',
    saveQuestionnareData:BASE_URL+'/Questionnaire/SaveData',
    searchQuestionnareData:BASE_URL+'/Questionnaire/Search',
    getQuestionnaireTemplate:BASE_URL+'/Questionnaire/GetTemplates',
    saveQuestionnaireTemplate:BASE_URL+'/Questionnaire/ImportTemplate',
    deleteQuestionnaireTemplate:BASE_URL+'/Questionnaire/Delete',
    getQuestionnaireImportHistory:BASE_URL+'/Questionnaire/GetQuestionnaireImportHistory',
    getAllQuestionnaireSets:BASE_URL+'/Questionnaire/GetAllQuestionnaireSets',
    getAllPrograms:BASE_URL+'/Questionnaire/GetAllPrograms',
    getAllProjectsByProjectId:BASE_URL+'/Questionnaire/GetAllProjectsByProgramId',
    saveMapping:BASE_URL+'/Questionnaire/SaveMapping',
    GetQuestionnaireMappingSets:BASE_URL+'/Questionnaire/GetQuestionnaireProgramProjectList',
    deleteQuestionnaireMappingTemplate:BASE_URL+'/Questionnaire/DeleteQuestionnaireProgramProject',
    updateStatusQuestionnaireMappingTemplate:BASE_URL+'/Questionnaire/ActiveQuestionnaireProgramProject',
    getPreviewData:BASE_URL+'/Questionnaire/GetDataPreview',
    deletes3Files:BASE_URL + '/Project/DeleteS3Files'
};
