import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);
  public isInternalLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);
  totalRequests = 0;
  completedRequests = 0;
  constructor() { }
  showLoading(internal?,url?) {    
    if (internal) {      
      this.isInternalLoading.next(true);
    }else{      
      this.totalRequests++;
      this.isLoading.next(true);
    }
  }
  hideLoading(internal?) {
    if (internal) {
        this.isInternalLoading.next(false);
    }else{
      this.completedRequests++;
      if (this.completedRequests>=this.totalRequests) {
        this.isLoading.next(false);
        this.completedRequests = 0;
        this.totalRequests = 0;
      }
    }
  }
  degradeRequest(n=1){
    this.totalRequests=this.totalRequests-n;
  }
}
