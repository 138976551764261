import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
/**
 *    This service is a wrapper function for basic http api's
 *    Service is used to set common header, options
 *
 */
@Injectable(
  {
    providedIn: 'root'
  }
)
export class ApiHttpService {
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  constructor(private httpClient: HttpClient) { }
  // Wrapper used for getting response in json format
  public get(path: string, params?: HttpParams, headers?: HttpHeaders,observe?): Observable<any> {
    if (headers || params) {
      return this.httpClient
        .get(path, { params, headers,observe:observe||'body' })
        .pipe(catchError(this.formatErrors));
    } else {
      return this.httpClient
        .get(path,{observe:observe||'body'})
        .pipe(catchError(this.formatErrors));
    }
  }
  // Wrapper function to get text 
  public getText(path: string,headers?:any): Observable<any> {
    return this.httpClient
      .get(path, { headers, responseType: 'text' })
      .pipe(catchError(this.formatErrors));
  }
  public getBlob(path: string): Observable<any> {
    return this.httpClient
      .get(path, { responseType: 'blob' })
      .pipe(catchError(this.formatErrors));
  }
  public getBlobWithFullResponse(path: string, params: any): Observable<any> {
    return this.httpClient
      .get(path, { params ,responseType: 'blob' })
      .pipe(catchError(this.formatErrors));
  }

  // wrapper  used for sending json request
  public put(path: string, body: any): Observable<any> {
    return this.httpClient
      .put(path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  // wrapper  used for sending form data
  public Fileput(path: string, body: any, options): Observable<any> {
    return this.httpClient
      .put(path, body, options)
      .pipe(catchError(this.formatErrors));
  }

 // wrapper used for sending normal json request
  public post(path: string, body: any): Observable<any> {
    return this.httpClient
      .post(path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }
  // wrapper  used for sending form data
  public formPost(path: string, body: any): Observable<any> {
    const options = { headers: new HttpHeaders().set('Content-Type-bypass', 'true') };
    return this.httpClient
      .post(path, body, options)
      .pipe(catchError(this.formatErrors));
  }
  // Basic delete wrapper
  public delete(path: string): Observable<any> {
    return this.httpClient
      .delete(path)
      .pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error);
  }
}
