import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ROUTES } from '@core/constants/routes';
import { AuthService } from '@app/core/service/auth.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { User } from '@app/data/schema/user';
import { UserInfoService } from '@app/modules/home/service/user-info.service';
import { StorageService } from '@app/shared/service/storage.service';
import { Storagekeys } from '@app/shared/constants/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/shared/service/api.service';
import { ProgramService } from '@app/modules/home/service/program.service';
import { LoaderService } from '@app/shared/service/interaction.service';
import { PopupService } from '@app/shared/service/popup.service';
import { API_URLS } from '@app/core/constants/api-url';
import { Program } from '@app/data/schema/program';
import { Notifications } from '@app/data/schema/notification';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit {
  subject$: Subject<any> = new Subject<User>();
  userInfo: User
  imgUrl: any;
  name: string = ''
  isActive = false;
  isNotifications = false;
  routes = ROUTES;
  permission: any;
  role: any; searchSubject$: Subject<string> = new Subject<string>();
  token: string;
  programs: Notifications[] = [];
  sortObj = {
    order: "asc",
    column: ""
  };
  dynamicBredcrumb: any = {};
  showPopUp: boolean = false;
  selectedFilterObj = {};
  selectedDeleteProgram: Notifications;
  userList = []
  isEdit = false;
  programAction;
  toggleOptions = false;
  gridView = false;
  tableView = true;
  pagination = {
    limit: 10,
    totalPrograms: 0,
    totalPages: 0,
    page: 1,
  }
  searchText: string;
  route = ROUTES;

  public _isGlobaladmin: boolean = false;
  private _subject1$: Subject<any> = new Subject<any>();
  customFieldChangeData = []
  constructor(
    private authService: AuthService,
    private _storage: StorageService,
    private apiService: ApiService,
    private programService: ProgramService,
    public is: LoaderService,
    private storage: StorageService,
    private popup: PopupService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    public _uis: UserInfoService,) {
    this.searchSubject$.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(searchText => {
        this.getPrograms(searchText);
      });
    this.activatedRouter.queryParams.subscribe((data) => {
      if (data && data.add) {
        this.toggleProgram(true)
      }

    })
    this.dynamicBredcrumb = [{
      name: 'Mange Notification',
      label: 'Manage Notifications'
    }];
  }

  ngOnInit() {
    this.getPrograms(this.searchText);
    this.toggleTableview();
    this.getOIDCUserInfo();

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  getTimeAgoFromDate(value) {
    if (!value) { return 'a long time ago'; }
    let time = (Date.now() - Date.parse(value)) / 1000;
    if (time < 10) {
      return 'just now';
    } else if (time < 60) {
      return 'a moment ago';
    }
    const divider = [60, 60, 24, 30, 12];
    const string = [' second', ' minute', ' hour', ' day', ' month', ' year'];
    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }
    const plural = Math.floor(time) > 1 ? 's' : '';
    return Math.floor(time) + string[i] + plural + ' ago';

  }

  getProgram(id) {
    this.programService.getProgram(id).pipe(takeUntil(this.subject$))
      .subscribe((data) => {
        if (data) {

          // this.peoplePicker.cd.detectChanges();
          let url = API_URLS.getPermissions;
          this.apiService.doGet(url).pipe(takeUntil(this._subject1$))
            .subscribe((data) => {
              var arrayRes = data?.programResults?.filter(o => o.programId === id)[0]?.roleDetails?.roleId
              if (arrayRes == undefined)
                arrayRes = data?.roleId;
              this._isGlobaladmin = arrayRes && arrayRes === 1

              // this.permissionService.setPermission(data);

            });
        }
      }, (err) => {
        console.error(err);
      });
  }
  navigate(program) {
    this.toggleNotifications()
    var programNew = { programId: program?.programId, name: program?.programName };
    this.storage.storeJSON(Storagekeys.program, programNew);

    if (program?.dataSourceId && program?.programId === 0) {
      this.router.navigate(['/dashboard/data-sources']);
    }
    else if (program?.projectId) {
      this.router.navigate(['/dashboard/projects/' + program?.projectId + '/details']);
    } else
      this.router.navigate(['/dashboard/program/' + program?.programId + '/projects']);
  }
  createProject(program) {
    this.toggleNotifications();
    this.storage.storeJSON(Storagekeys.program, program)
    this.router.navigate(['/dashboard/program/' + program?.programId + '/project/create'])
  }

  deleteAttribute(isCustom, field) {
    // } else {
    //   this.confirmAttributeDelete();
    // }
  }
  sortColumn(column, order) {

    this.sortObj.column = column;
    this.sortObj.order = order
    this.getPrograms();

  }
  ascsortColumn(column, order) {
    this.sortObj.column = column;
    this.sortObj.order = order
    this.getPrograms();
  }
  formatTableDataRequest(reset?) {
    let userInfo = this.storage.getJSON(Storagekeys.user);
    return {
      name: this.searchText,
      limit: Number(this.pagination?.limit),
      page: reset ? 1 : Number(this.pagination?.page),
      id: userInfo?.id,
      email: userInfo?.emailId,
      sort: this.getSortData(),
      filters: this.formatFilterData(this.selectedFilterObj)
    }
  }
  formatFilterRequest(columnName, searchText) {
    let userInfo = this.storage.getJSON(Storagekeys.user);
    return {
      id: userInfo?.id,
      email: userInfo?.emailId,
      sort: this.getSortData(),
      column: columnName,
      searchText: searchText,
      filters: this.formatFilterData(this.selectedFilterObj)
    }
  }
  getSortData() {
    return this.sortObj?.column && this.sortObj?.order ? this.sortObj : {}
  }
  formatFilterData(filterObj) {
    // [{ "column": "Location" ,"values": ["mumbai", "delhi"] } ]
    let filterColumn = []
    for (const key in filterObj) {
      const element = filterObj[key];
      if (element && element?.length) {
        filterColumn.push({
          column: key,
          values: element
        })
      }
    }
    return filterColumn;
  }
  getPrograms(reset?) {
    // console.log(searchText);

    let userInfo = this.storage.getJSON(Storagekeys.user);
    // let filter = {
    //   name: searchText,
    //   limit: this.pagination?.limit,
    //   page: this.pagination?.page,
    //   id: userInfo?.id,
    //   email: userInfo?.emailId
    // }
    let filter = this.formatTableDataRequest(reset);
    //  if (userInfo?.emailId && userInfo?.id) {
    this.programService.getnotifications(filter).pipe(takeUntil(this.subject$))
      .subscribe((data: any) => {
        if (data && data.programs) {
          const now = new Date();


          // 👇️ convert ms to hours                  min  sec   ms 
          this.programs = (data.programs || [])
          this.programs = this.programs.filter(e => {
            var d = new Date(e?.createdOn);
            return d.getTime() <= now.getTime();
          });
          this.programs = this.programs.slice(0, 4)
          this.pagination.totalPrograms = this.programs.length;
          this.pagination.totalPages = Math.ceil(this.pagination.totalPrograms / this.pagination.limit);
        }
      }, (err) => {
        console.error(err);
      });
    //  }

  }


  toggleProgram(add?, program?) {
    this.programAction = add;
    this.isEdit = !this.isEdit;
    if (program?.programId) {
      //this.program.setControl("programId", new FormControl(program?.programId));
      this.getProgram(program?.programId);
    }
    if (program == undefined) {
      this._isGlobaladmin = true;
    }
  }
  formatAdmins(data) {
    return data?.inviteUsers?.programAdmins?.map(o => o?.fullName).filter(itm => itm?.trim()?.length > 0)?.join(",")
  }
  deleteProgram(program) {
    this.isActive = !this.isActive;
    this.selectedDeleteProgram = program;
  }
  confirmDelete() {
    let programId = this.selectedDeleteProgram?.notificationId;
    let user = this.storage.getJSON(Storagekeys.user)
    this.programService.deleteProgram({ id: programId, userId: user?.id }).pipe(takeUntil(this.subject$))
      .subscribe((data) => {
        this.isActive = !this.isActive;
        this.getPrograms(this.searchText);
      }, (err) => {
        this.popup.showPopup('danger', 'Delete Program Failed');
        console.error(err);
      });
  }
  viewOptions() {
    this.toggleOptions = !this.toggleOptions;
  }
  closeViewOptions() {
    this.toggleOptions = false
  }
  toggleGridview() {
    this.gridView = true;
    this.tableView = false;
    this.toggleOptions = false;
  }
  toggleTableview() {
    this.tableView = true;
    this.gridView = false;
    this.toggleOptions = false;
  }
  setLimit() {
    this.pagination.page = 1;
    this.pagination.totalPages = Math.ceil(Number(this.pagination.totalPrograms) / Number(this.pagination.limit));
    this.getPrograms("");
  }
  next() {
    if (Number(this.pagination?.page) < Number(this.pagination.totalPages)) {
      this.pagination.page = Number(this.pagination?.page) + 1;
      this.getPrograms("");
    }
  }
  prev() {
    if (Number(this.pagination?.page) > 0) {
      this.pagination.page = Number(this.pagination?.page) - 1;
      this.getPrograms("");
    }
  }
  getOIDCUserInfo() {
    this.authService.getOIDCUserInfo().pipe(takeUntil(this.subject$))
      .subscribe((data) => {
        this.userInfo = data;
        let name = `${this.userInfo?.given_name || ''} ${this.userInfo?.family_name || ''}`
        this._storage.storeJSON(Storagekeys.user, { fullName: (name?.trim() || ''), ...this._storage.getJSON(Storagekeys.user) });
        this.getUserProfileImage(this.userInfo?.picture);
      }, (err) => {
        console.error(err);
      });
  }
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load",
      () => {
        this.imgUrl = reader.result;
      },
      false);
    if (image) {
      if (image.type !== "application/pdf")
        reader.readAsDataURL(image);
    }
  }
  getUserProfileImage(imgUrl) {
    this.authService.getProfile(imgUrl).pipe(takeUntil(this.subject$))

  }

  setNameAlias() {
    this.name = `${this.userInfo?.given_name?.charAt(0) || this.userInfo?.email?.charAt(0)}${(this.userInfo?.family_name?.charAt(0) || this.userInfo?.email?.charAt(1))}`
  }

  toggleModel() {
    this.isActive = !this.isActive;
  }
  toggleNotifications() {
    this.isNotifications = !this.isNotifications;
  }
  toggleOutNotifications() {
    this.isNotifications = false;
  }
  closeDropdown() {
    this.isActive = false;
  }

  goNo() {
    this.isNotifications = false;
    this.router.navigate(['/dashboard/notification'])
  }  
  goToQuestionnaire()
  {
    this.isNotifications=false;
    this.router.navigate(['/dashboard/viewquestionnaires'])
  }
  ngOnDestroy(): void {
    this.getOIDCUserInfo();
    this.subject$.next();
    this.subject$.complete();
  }
}
