import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '@env';
import { StorageService } from '@app/shared/service/storage.service';
import { Storagekeys } from '@app/shared/constants/storage';
import { ApiService } from '@app/shared/service/api.service';
import { AuthService } from '@app/core/service/auth.service';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { AuthenticationResult } from '@azure/msal-browser';
import { throwError } from 'rxjs';

// @Injectable()
// export class TokenInterceptor implements HttpInterceptor {
//   constructor(private storage:StorageService){}
//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     const isPeoplePickerApiUrl = request.url.startsWith(environment.peoplePickerUrl);
//     let token=this.storage.getItem(Storagekeys.peoplePickerToken)
//     if (isPeoplePickerApiUrl && token) {
//         request = request.clone({
//             setHeaders: { Authorization: `Bearer ${token}` }
//         });
//     }
//     return next.handle(request);
//   }
// }

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  modifiedReq: any;

  constructor(private _StorageService: StorageService, private apiService: ApiService, private _AuthService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.indexOf("graph.microsoft.com") > -1||  req.url.indexOf("s3.amazonaws.com")>-1) {
      return next.handle(req);
    }
    
    if (req.url.indexOf(environment.peoplePickerUrl) >= 0) {
      let token=this._StorageService.getItem(Storagekeys.peoplePickerToken)
      if (!token || this._AuthService.isTokenExpired(token)){
        return this._AuthService.fetchPeoplePickerToken().pipe(switchMap((res: any) => {
          return this.handleRequest(req, next, res.accessToken);
        }));
      }
      else{
        return this.handleRequest(req, next, token);
      }    
    }  
  
    let token=this._StorageService.getItem(Storagekeys.token)
    if (!token || this._AuthService.isTokenExpired(token)) {
      return this._AuthService.getTokenSilent().pipe(switchMap((authResult: AuthenticationResult) => {  
        return this.handleRequest(req, next, authResult.idToken);
      }));
    }
    return this.handleRequest(req, next, token);    
   
  }

  injectToken(request: HttpRequest<any>, token: any) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  handleRequest(req: HttpRequest<any>, next: HttpHandler, token: any) {
    return next.handle(this.injectToken(req, token))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            // console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;

            // this.failedService.isErrorOccured = true;
            // this.failedService.errorObj.errorMsg = 'Internal Client Server Error Occurred';
          }
          else {

            // console.log('this is server side error');
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;


            if (error.status !== 409) {
              // this.failedService.isErrorOccured = true;
              // this.failedService.errorObj.errorMsg = 'Internal Server Error Occurred';
            }
            if(error.status == 500){
                if(error.error.includes('Connection Issue:') || error.error.includes('Query Issue:')){
                  errorMsg = error.error;
                } else {
                  errorMsg = error.error;
                }
            }
            if(error.status == 302){
              errorMsg = error.error;
            }
          }
          console.log(errorMsg);
          return throwError(errorMsg);
        }),
        finalize(() => {
          // this.failedService.hide();
        }),
      );

  }
}
