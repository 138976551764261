// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  // serverUrl: 'https://620f5d37ec8b2ee2833cfc40.mockapi.io',
  serverUrl: 'https://dapi-us.octopus.deloitte.com/api',
  //serverUrl: 'https://localhost:44362/api',
 
  envName: 'DEV',
  msalClientId:'3e571461-7d57-4b35-9ac5-8d44cb998e1c',
  msalTenantId:'36da45f1-dd2c-4d1f-af13-5abe46b99921',
  peoplePickerUrl:'https://people.sm.us.deloitte.com',
  peoplePickerScope:['https://people.sm.us.deloitte.com/AZ/People/Photo//user_impersonation', 'user.read']
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
