<div class="black-bg py10 ">
  <div class="main-container">
    <footer class="white-text">
      <p>
        Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited
        (“DTTL”), its global network of member firms, and their related entities
        (collectively, the “Deloitte organization”). DTTL (also referred to as
        “Deloitte Global”) and each of its member firms and related entities are
        legally separate and independent entities, which cannot obligate or bind
        each other in respect of third parties. DTTL and each DTTL member firm
        and related entity is liable only for its own acts and omissions, and
        not those of each other. DTTL, Deloitte GFS Limited and Deloitte GFS
        Holdings Limited do not provide services to clients. Please see
        <a class="contact-us" target="_blank" href="https://www.deloitte.com/about">www.deloitte.com/about</a> to learn more.
      </p>

      <p>
        This communication is for internal distribution among personnel of
        Deloitte Touche Tohmatsu Limited, its global network of member firms and
        their related entities. It may contain confidential information and is
        intended solely for the use of the individual or entity to whom it is
        addressed. If you are not the intended recipient, please notify us
        immediately and then delete this communication and all copies of it on
        your system. Please do not use this communication in any way.
      </p>

      <p>
        None of DTTL, its member firms, related entities, employees or agents
        shall be responsible for any loss or damage whatsoever arising directly
        or indirectly in connection with any person relying on this
        communication.
      </p>
      <p>© 2021. For information, contact Deloitte GFS Limited</p>
    </footer>
  </div>
</div>
