import { Injectable } from '@angular/core';
import { IStorageService, StorageItem, Storagekeys } from '../constants/storage';
@Injectable(
  {
    providedIn: 'root'
  }
)
/*
  Storage Type: Local Storage

  This class can be used to manage storage 
  We can easily swith between type of storage (local storage/session storage) used in this class, 
  encode or decode data stored without having impact on other services
*/
export class StorageService implements IStorageService {
  constructor() { }
  storeItem(key: Storagekeys, item: any) {
    localStorage.setItem(key, btoa(item));
  }
  storeJSON(key: Storagekeys, item: any) {
    localStorage.setItem(key, btoa(JSON.stringify(item)));
  }
  storeAllItem(items: Array<StorageItem>) {
    items.forEach(item => {
      this.storeItem(item.key, btoa(item.value));
    });
  }
  getItem(key: Storagekeys): any {
    return localStorage.getItem(key) ? atob(localStorage.getItem(key) || '') : undefined;
  }
  getJSON(key: Storagekeys): any {
    return localStorage.getItem(key) ? JSON.parse(atob(localStorage.getItem(key)) || '') : undefined;
  }
  removeItem(key: Storagekeys) {
    localStorage.removeItem(key);
  }

  // common function for resetting after logout
  resetUserSession() {
    localStorage.clear();
  }
}
