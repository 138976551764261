import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  title = 'Angular 12 - Angular v2 Sample';
  isIframe = false;
  loginDisplay = false;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private _Router:Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}
    ngOnInit(): void {
      this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
      this.setLoginDisplay();
  
      this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        )
        .subscribe((result: EventMessage) => {
          console.log(result);
          localStorage.setItem("pott3",JSON.stringify(result))
          
          if (this.authService.instance.getAllAccounts().length === 0) {
            // window.location.pathname = "/";
            this._Router.navigate(["/"]);
          } else {
            this.setLoginDisplay();
          }
        });
      
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        });
        this.injectAnalyticsDataLayer();
    }
  
    injectAnalyticsDataLayer() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        var analyticsDataLayer = {
            'pageInfo': {
                'pageName': "Home",
                'userID': "",
                'applicationName': "Octopus"
            }
        };
        _satellite.track("pageView");
      `;
      document.head.appendChild(script);
    }
    
    setLoginDisplay() {
      this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }
  
    checkAndSetActiveAccount(){
      /**
       * If no active account set but there are accounts signed in, sets first account to active account
       * To use active account set here, subscribe to inProgress$ first in your component
       * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
       */
      let activeAccount = this.authService.instance.getActiveAccount();
  
      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        this.authService.instance.setActiveAccount(accounts[0]);
      }
    }
  
    loginRedirect() {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  
    loginPopup() {
      if (this.msalGuardConfig.authRequest){
        this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            console.log(response);
            localStorage.setItem("pott",JSON.stringify(response))
            this.authService.instance.setActiveAccount(response.account);
          });
        } else {
          this.authService.loginPopup()
            .subscribe((response: AuthenticationResult) => {
              console.log(response);
              localStorage.setItem("pott1",JSON.stringify(response))
              this.authService.instance.setActiveAccount(response.account);
        });
      }
    }
  
    logout(popup?: boolean) {
      if (popup) {
        this.authService.logoutPopup({
          mainWindowRedirectUri: "/"
        });
      } else {
        this.authService.logoutRedirect();
      }
    }
  
    ngOnDestroy(): void {
      this._destroying$.next(undefined);
      this._destroying$.complete();
    }
  
}
