import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PermissionService } from '@app/modules/home/service/permission.service';
import { UserInfoService } from '@app/modules/home/service/user-info.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _uis:UserInfoService,private _ps:PermissionService,private _router:Router){}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(res => {
      this._ps.getPermissions().subscribe(
            (config ) => {
              console.log(config);
                if (config?.roleId==1) {
                    res(true);
                } else {
                    res(false);
                    this._router.navigate(["/dashboard/not-found"])
                }
            },
            (error) => {
              console.log(error);
              res(false);
              this._router.navigate(["/dashboard/not-found"])
            }
        );
    });
}
}
