import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
@Directive({
  selector: '[appClickOutside]'
})
// This directive is commonly used to identify outside click of some container
// This can be used to close popups

export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();
  constructor(private elementRef: ElementRef) {
  }
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef?.nativeElement?.contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
