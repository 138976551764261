import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  public isPopupVisible: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private messages: any[] = [];
  constructor() { }
  showPopup(type?, message?) {
    this.messages.push({
      type,
      message
    })
    this.isPopupVisible.next(true);
  }
  hidePopup() {
    this.isPopupVisible.next(false);
  }
  hideMessage(index?) {    
    if (index>=0) {
      this.messages.splice(index,1);
    }
  }
  getMessage() {
    return this.messages
  }
  resetMessage() {
    this.messages = []
    this.isPopupVisible.next(false);
  }
}
