import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent {
  isVisible:boolean=false;
  constructor(private _Router:Router) {
    this._Router.events.subscribe((res)=>{
      if (this._Router.url=="/dashboard") {
        this.isVisible=true;
      }else{
        this.isVisible=false;
      }
    })
  }
}
