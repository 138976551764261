import { Injectable } from '@angular/core';
import { Observable, Observer, throwError } from 'rxjs';
import { ApiHttpService } from './api-http.service';
import { APIConfigOptions } from './api-options';
import { LoaderService } from './interaction.service';
import { PopupService } from './popup.service';
/**
 * This file uses api-http.service for rest api call.
 * This service used to handle custom logic on response/error/common loader/common alert
 * from api response common throught application level
 * 
 * This will be using wrapper functions in api-http.service to implement http methods
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private apiHttpService: ApiHttpService,
    private is: LoaderService,
    private popup: PopupService
  ) { }

  doPost(url: string, reqBody: any, config?: APIConfigOptions) {
    if (!config || config?.loaderVisible) {
      this.is.showLoading();
    }
    return new Observable<any>((observer: Observer<any>) => {
      this.apiHttpService.post(url, reqBody)
        .subscribe(data => {
          // check if api status code success
            if (!config || config?.loaderVisible) {
              this.is.hideLoading();
            }
            if (config && config?.popupVisible) {
              this.popup.showPopup('success',config?.popupMessage);
            }
            observer.next(data);
            observer.complete();
          
        }, error => {          
          this.handleError(error, config, observer);
        });
    });
  }

  doPut(url: string, reqBody: any, config?: APIConfigOptions) {
    if (!config || config?.loaderVisible) {
      this.is.showLoading();
    }
    return new Observable<any>((observer: Observer<any>) => {
      this.apiHttpService.put(url, reqBody)
        .subscribe(data => {
          // check if api status code success
            if (!config || config?.loaderVisible) {
              this.is.hideLoading();
            }
            if (config && config?.popupVisible) {
              this.popup.showPopup('success',config?.popupMessage);
            }
            observer.next(data);
            observer.complete();
          
        }, error => {
          this.handleError(error, config, observer);
        });
    });
  }

  doPutFile(url: string, reqBody: any, config?: APIConfigOptions,httpOptions?) {
    if (!config || config?.loaderVisible) {
      this.is.showLoading();
    }
    return new Observable<any>((observer: Observer<any>) => {
      this.apiHttpService.Fileput(url, reqBody,httpOptions)
        .subscribe(data => {
          // check if api status code success
            if (!config || config?.loaderVisible) {
              this.is.hideLoading();
            }
            if (config && config?.popupVisible) {
              this.popup.showPopup('success',config?.popupMessage);
            }
            observer.next(data);
            observer.complete();
          
        }, error => {
          this.handleError(error, config, observer);
        });
    });
  }

  doDelete(url: string, config?: APIConfigOptions) {
    if (!config || config?.loaderVisible) {
      this.is.showLoading();
    }
    return new Observable<any>((observer: Observer<any>) => {
      this.apiHttpService.delete(url)
        .subscribe(data => {
          // check if api status code success
            if (!config || config?.loaderVisible) {
              this.is.hideLoading();
            }
            if (config && config?.popupVisible) {
              this.popup.showPopup('success',config?.popupMessage);
            }
            observer.next(data);
            observer.complete();
         
        }, error => {
          this.handleError(error, config, observer);
        });
    });
  }

  doFormPost(url: string, body: any, config?: APIConfigOptions) {
    if (!config || config?.loaderVisible) {
      this.is.showLoading();
    }
    return new Observable<any>((observer: Observer<any>) => {
      this.apiHttpService.formPost(url, body)
        .subscribe(data => {
          if (!config || config?.loaderVisible) {
            this.is.hideLoading();
          }
          // check if api status code success
          if (data.status || data.status === 200) {
            if (config && config?.popupVisible) {
              this.popup.showPopup('success',config?.popupMessage);
            }
            observer.next(data);
            observer.complete();
          } else {
            this.handleError(data, config, observer);
          }
        }, error => {
          this.handleError(error, config, observer);
        });
    });
  }
  doGetText(url: string, config?: APIConfigOptions,headers?:any) {
    if (!config || config?.loaderVisible) {
      this.is.showLoading();
    }
    return new Observable<any>((observer: Observer<any>) => {
      this.apiHttpService.getText(url,headers)
        .subscribe(data => {
          if (!config || config?.loaderVisible) {
            this.is.hideLoading();
          }
          if (config && config?.popupVisible) {
            this.popup.showPopup('success',config?.popupMessage);
          }
          observer.next(data);
          observer.complete();
        }, error => {
          this.handleError(error, config, observer);
        });
    });
  };

  doGetBlob(url: string, config?: APIConfigOptions) {
    if (!config || config?.loaderVisible) {
      this.is.showLoading();
    }
    return new Observable<any>((observer: Observer<any>) => {
      this.apiHttpService.getBlob(url)
        .subscribe(data => {
          if (!config || config?.loaderVisible) {
            this.is.hideLoading();
          }
          observer.next(data);
          observer.complete();
        }, error => {
          this.handleError(error, config, observer);
        });
    });
  };

  doGetBlobWithResponse(url: string,reqBody?:any, config?: APIConfigOptions) {
    if (!config || config?.loaderVisible) {
      this.is.showLoading();
    }
    return new Observable<any>((observer: Observer<any>) => {
      this.apiHttpService.getBlobWithFullResponse(url,reqBody)
        .subscribe(data => {
          if (!config || config?.loaderVisible) {
            this.is.hideLoading();
          }
          observer.next(data);
          observer.complete();
        }, error => {
          this.handleError(error, config, observer);
        });
    });
  };

  doGet(url: string, config?: APIConfigOptions, param?: any, headers?: any,observe?:any) {
    if (!config || config?.loaderVisible || config?.internalLoaderVisible) {
      this.is.showLoading(config?.internalLoaderVisible,url);
    }
    return new Observable<any>((observer: Observer<any>) => {
      this.apiHttpService.get(url, param, headers,observe)
        .subscribe(data => {          
          if (!config || config?.loaderVisible || config?.internalLoaderVisible) {            
            this.is.hideLoading(config?.internalLoaderVisible);
          }
          if (config && config?.popupVisible) {
            this.popup.showPopup('success',config?.popupMessage);
          }
          observer.next(data);
          observer.complete();
        }, error => {
          this.handleError(error, config, observer,config?.internalLoaderVisible);
        });
    });
  };

  handleResponse(res: any): any {
    return res;
  }

  // this method can be used to tranform or format BE Response
  handleError(error: any, config?: any, observer?: any,internalLoading?) {
    this.is.hideLoading(internalLoading);    
    let errorMessage = 'Some error on server occured';
    if (error?.status?.userMessage) {
      errorMessage = error?.Message;
    } else {
      errorMessage = (error?.errorMessage || errorMessage);
    }
    throwError(error);
    if (observer) {
      observer.error(error);
      observer.complete();
    }
  }
}
