<nav aria-label="breadcrumb" class="breadcrumb">
    <ol class="breadcrumb">
        <ng-container *ngFor="let navItem of (navItems||[]);let i= index">
            <li  *ngIf="navItem?.visible" class="breadcrumb-item"  [ngClass]="!navItem?.terminal?'':'active'">
                <a *ngIf="navItem?.active && i <= 1" [routerLink]="[navItem?.route]"> <label style="text-transform: capitalize;" title="{{navItem?.label||''}}">{{navItem?.label||''}}</label></a>
                <a *ngIf="navItem?.active && i >1" [routerLink]="[navItem?.route]" [queryParams]="{changeProject: 'true'}"> <label style="text-transform: capitalize;" title="{{navItem?.label||''}}">{{navItem?.label||''}}</label></a>
                <label (click)="checkTerminal(navItem)" class="inactive-label" [ngStyle]="{'text-transform': !navItem?.terminal?'capitalize':'uppercase'}" *ngIf="!navItem?.active" title="{{navItem?.label}}">{{navItem?.label}}</label>
            </li>
        </ng-container>
    </ol>
</nav>