import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Storagekeys } from '@app/shared/constants/storage';
import { StorageService } from '@app/shared/service/storage.service';

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessPopupComponent implements OnInit, OnDestroy {
  data
  constructor(private activatedRoute:ActivatedRoute,private storage:StorageService) { 
    this.activatedRoute.data.subscribe(params => {
      this.data=this.storage.getJSON(Storagekeys.project)
      
    });

  }
  ngOnDestroy(): void {
    this.storage.removeItem(Storagekeys.project)
  }

  ngOnInit(): void {
  }

}
