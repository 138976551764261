<div class="sticky-top black-bg" >
  <div class="main-container">
    <nav class="navbar">
      <a class="navbar-brand" routerLink="/">
        <img
          src="../../../assets/images/deloitte-logo.svg"
          alt=""
          height="18"
        />
      </a>
      <div class="d-flex align-items-center">
        <div class="notifications" appClickOutside (clickOutside)="toggleOutNotifications()">
          <button
            class="btn icon-btn pa0 notifications__btn"
            (click)="toggleNotifications()"
          >
            <span class="material-icons"> notifications </span>
            <small>{{programs?.length}}</small>
          </button>
          <div  class="notifications__panel" [class.active]="isNotifications">
            <div class="row">
              <div class="col">
                <h4>
                  <span class="material-icons"> notifications </span>Notifications
                  <small>{{programs?.length}}</small>
                </h4>
              </div>
              <div class="col text-right">
                <span
                  class="material-icons f-600 notifications__panel--close"
                  (click)="toggleNotifications()"
                >
                  close
                </span>
              </div>
            </div>
            <div class="row mt3">
              <ng-container *ngFor="let program of (programs||[])">
            
              <div class="col-6">
                <div class="notifications__tile">
                  <div class="row">
                    <div class="col">
                      <h6>ACTION REQUIRED</h6>
                    </div>
                    <div class="col text-right">
                      <span class="time"><b>{{ getTimeAgoFromDate(program?.createdOn) }}</b></span>
                    </div>
                  </div>
                  <p>
                    {{program?.programName}} &nbsp; {{program?.descripton}}
                  </p>
                  <button class="btn btn-link pa0" (click)="navigate(program)">
                    See Details
                  </button>
                </div>
              </div>
        </ng-container>
        
        
              <div class="col-6">
                 
              </div>
              <div class="col-6">
                
              </div>
            </div>
             
        <button class="btn btn-link pa0 text-uppercase" >
          <a (click)="goNo()">  See More </a>
       </button>
          </div>
        </div>
        <div class="profile" appClickOutside (clickOutside)="closeDropdown()" (click)="toggleModel()">
          <img *ngIf="imgUrl" [src]="imgUrl" alt="" />
          <span class="custom-prof-img" *ngIf="!imgUrl && name">{{name}}</span>
          <span class="profile__name">
            <strong>{{userInfo?.given_name}} {{userInfo?.family_name}}</strong>
            <small>{{(_uis.roleInfo$|async)?.name}}</small>
          </span>
          <div class="profile-menu"   [class.active]="isActive" > 
            <!-- <div class="profile-menu__header">
              <strong>{{userInfo?.given_name}} {{userInfo?.family_name}}</strong>
              <small>TBD</small>
              <span>Last Session: TBD</span>
            </div> -->
            <ul>
              <li *ngIf="(_uis.roleInfo$|async)?.id==1">
                <span [routerLink]="['dashboard','manage-roles']">Manage Role(S)</span>
              </li>
              <li *ngIf="(_uis.roleInfo$|async)?.id==1 || (_uis.roleInfo$|async)?.id==2">
                <span [routerLink]="['dashboard','data-sources']">Data Sources</span>
              </li>
              <li>
                <span [routerLink]="['dashboard','programs']">Programs</span>
              </li>
              <!-- <li *ngIf="(_uis.roleInfo$|async)?.id==1">
                <span [routerLink]="['dashboard','manage-reporting']">Manage Reporting</span>
              </li> -->
              <li (click)="goToQuestionnaire()"><span>Questionnaires</span></li>
              <li (click)="goNo()"><span>Notifications</span></li>
            </ul>
            <!-- <button type="button" class="btn btn-primary small ma2">
              Sign Off
            </button> -->
          </div>
        </div>
      </div>
    </nav>
  </div>
  
</div>

