<div class="inner-view">
    <h4 class="mt0">SUCCESS <span class="material-icons-outlined">info</span></h4>
    <div class="custom-alert alert-success my-5">
        <div class="custom-alert__header">
          <span class="material-icons"> done </span> Success
        </div>
        <div class="custom-alert__info ml5">
            Project <b>{{data?.name}}</b> has been {{data?.isEdit?'updated':'created'}} succesfully. Click the below button to view the project details.
        </div>
      </div>
      <hr>
    <button class="btn btn-primary medium mt2" [routerLink]="[data?.path]">
     Go To Project List
    </button>
</div>