import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { PermissionService } from "@app/modules/home/service/permission.service";
import { UserInfoService } from "@app/modules/home/service/user-info.service";
import { Observable } from "rxjs";

@Injectable()
export class DataSourceGuard implements CanActivate {
    constructor(private _uis: UserInfoService, private _ps: PermissionService, private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Promise(res => {
            this._ps.getPermissions().subscribe((config) => {
                if (this.isAllowed(config)) {
                    res(true);
                } else {
                    res(false);
                    this._router.navigate(["/dashboard/not-found"])
                }
            },
                (error) => {
                    console.log(error);
                    res(false);
                    this._router.navigate(["/dashboard/not-found"])
                }
            );
        });
    }

    private isAllowed(config: any): boolean {
        if (config?.roleId == 1 || config?.roleId == 2) return true;
        if (config.programResults && config.programResults.length > 0) {
            let programResult: any;
            for (let i = 0; i < config.programResults.length; i++) {
                programResult = config.programResults[i];
                if (programResult.roleDetails.roleId == 2) return true;
            }
        }
        if (config.projectResults && config.projectResults.length > 0){
            let projetResult: any;
            for (let i = 0; i < config.projectResults.length; i++) {
                projetResult = config.projectResults[i];
                if (projetResult.roleDetails.roleId == 2) return true;
            }
        }
        return false;
    }

}