import { Injectable } from '@angular/core';
import { API_URLS } from '@app/core/constants/api-url';
import { Storagekeys } from '@app/shared/constants/storage';
import { ApiService } from '@app/shared/service/api.service';
import { StorageService } from '@app/shared/service/storage.service';
import { UserInfoService } from './user-info.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private apiService: ApiService, private storage: StorageService,private userInfoService:UserInfoService) { }

  hasProjectPermission(permissions, projectId, key) {
    if (permissions?.projectResults) {
      let project = permissions?.projectResults?.find(i => i?.projectId == projectId);      
      return project?.roleDetails?.permissions && project?.roleDetails?.permissions[key]
    } else {
      return permissions?.permissions && permissions?.permissions[key]
    }
  }
  hasProgramPermission(permissions, programId, key) {
    if (permissions?.programResults) {
      let program = permissions?.programResults?.find(i => i?.programId == programId)
      return program?.roleDetails?.permissions && program?.roleDetails?.permissions[key]
    } else {
      return permissions?.permissions && permissions?.permissions[key]
    }
  }
  hasProgramViewPermission(permissions, programId) {
    if (permissions?.programResults) {
      let program = permissions?.programResults?.find(i => i?.programId == programId);
      console.log(program);
      
      return program?.roleDetails?.permissions ?true:false
    } else {
      return true
    }
  }
  setRole(permissions, programId?, projectId?) {
    if (projectId) {
      if (permissions?.projectResults) {
        let project = permissions?.projectResults?.find(p => p?.projectId == projectId);        
        this.userInfoService.setRoleInfo(this.getRoleNameById(project?.roleDetails?.roleId));
      } else {
        this.userInfoService.setRoleInfo(this.getRoleNameById(permissions?.roleId))
      }
    } else if (programId) {
      if (permissions?.programResults) {
        let program = permissions?.programResults.find(p => p.programId == programId)
        this.userInfoService.setRoleInfo(this.getRoleNameById(program?.roleDetails?.roleId))
      } else {
        this.userInfoService.setRoleInfo(this.getRoleNameById(permissions?.roleId))
      }
      
    } else {
      if (permissions?.programResults || permissions?.projectResults) {
        let globalList = [...(permissions?.programResults || []), ...(permissions?.projectResults || [])]
        let rolesList = Array.from(new Set([...globalList?.map(p => Number(p?.roleDetails?.roleId))]))
        rolesList=rolesList.filter(val => val!= 0);
        let role = Math.min(...rolesList)
        this.userInfoService.setRoleInfo(this.getRoleNameById(role))
      } else {
        this.userInfoService.setRoleInfo(this.getRoleNameById(permissions?.roleId))
      }
    }
  }
  setPermission(permissions){
    this.userInfoService.setPermission(permissions)
  }
  private getRoleNameById(id) {
    let role = {
      1: "Global Admin",
      2: "Program Admin",
      3: "Project Admin",
      4: "Contributor",
      5: "Reader"
    }    
    return { name:role[id] ||"",id:id };
  }

  getPermissions() {
    let url = API_URLS.getPermissions;
    return this.apiService.doGet(url)
  }
}
