import { Injectable } from '@angular/core';
import { API_URLS } from '@app/core/constants/api-url';
import { Storagekeys } from '@app/shared/constants/storage';
import { ApiService } from '@app/shared/service/api.service';
import { StorageService } from '@app/shared/service/storage.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  private _roleInfo:Subject<any> = new BehaviorSubject<any>("");
  private _permission:Subject<any> = new BehaviorSubject<any>("");
  public readonly roleInfo$ = this._roleInfo.asObservable();
  public readonly permission$ = this._permission.asObservable();
  constructor() {}

  setRoleInfo(role) {
    this._roleInfo.next(role);
  }
  setPermission(permission) {
    this._permission.next(permission);
  }
  
}
