import { Injectable } from '@angular/core';
import { ApiService } from "../../../shared/service/api.service";
import { API_URLS } from "../../../core/constants/api-url";
import { Observable } from 'rxjs';
import { Program } from '@app/data/schema/program';
import { Notifications } from '@app/data/schema/notification';
@Injectable({ providedIn: 'root' })
export class ProgramService {

  constructor(private apiService: ApiService) { }

  getPrograms(): Observable<Program[]> {
    let url = API_URLS.getProgram + "?PageNo=1&PageSize=10&status=active";
    return this.apiService.doGet(url, { loaderVisible: false })
  }
  getDashboardPrograms(): Observable<Program[]> {
    return this.apiService.doGet(API_URLS.getDashboardPrograms, { loaderVisible: false })
  }
  getProgram(id) {
    let url = API_URLS.getProgramById + `/${id}`;
    return this.apiService.doGet(url)
  }
  addOrUpdateProgram(req) {
    let url = req.programID ? API_URLS.editProgram : API_URLS.addProgram;
    if (req.programID) {
      return this.apiService.doPut(url, req, { popupMessage: 'Updated Program Successfully', popupVisible: true, loaderVisible: true });
    } else {
      return this.apiService.doPost(url, req, { popupMessage: 'Created Program Successfully', popupVisible: false, loaderVisible: true });
    }
  }

  addOrUpdateNotification(req) {
    let url = API_URLS.addNotification;

    return this.apiService.doPost(url, req, { popupMessage: req.notificationId != 0 ? 'Update notification succesfully' : 'Created Notification Successfully', popupVisible: true, loaderVisible: true });

  }

  addOrUpdateNotifications(req) {
    let url = API_URLS.addNotifications;

    return this.apiService.doPost(url, req, { popupMessage: req.notificationId != 0 ? 'Update notification succesfully' : 'Created Notification Successfully', popupVisible: true, loaderVisible: true });

  }
  getProgramList(filter): Observable<Program[]> {
    let url = API_URLS.getProgram + `?`;
    let pagination = filter?.limit && filter?.page ? `PageNo=${filter?.page}&PageSize=${filter?.limit}` : ''
    let sort = filter?.sort && filter?.sort?.column ? `&SortBy.Column=${filter?.sort?.column}&SortBy.Order=${filter?.sort?.order}` : ''
    let searchText = filter.name ? `&searchText=${filter.name}` : '';
    let status = filter.status ? `&status=${filter.status}` : '';
    let manualReporting = filter.manualReporting ? `&manualReporting=${filter.manualReporting}` : '';
    url = url + pagination + sort + searchText + status + manualReporting;
    // let url = API_URLS.getProgram+`/${id}?pageSize=8&pageNo=1`;
    return this.apiService.doGet(url, { loaderVisible: true })
  }
  getnotifications(filter): Observable<Program[]> {
    let url = API_URLS.getnotifications + `?`;
    let pagination = filter?.limit && filter?.page ? `PageNo=${filter?.page}&PageSize=${filter?.limit}` : ''
    let sort = filter?.sort && filter?.sort?.column ? `&SortBy.Column=${filter?.sort?.column}&SortBy.Order=${filter?.sort?.order}` : ''
    let searchText = filter.name ? `&searchText=${filter.name}` : '';
    url = url + pagination + sort + searchText;
    // let url = API_URLS.getProgram+`/${id}?pageSize=8&pageNo=1`;
    let req = {
      pageNo: filter?.page,
      pageSize: filter?.limit,
      sortBy: filter?.sort && filter?.sort?.column ? { Column: filter?.sort?.column, Order: filter?.sort?.order } : null,
      searchText: filter.name ?? '',
      filterBy: '',
      status: ''
    };
    return this.apiService.doPost(API_URLS.getnotifications,req, { loaderVisible: true });
  }
  getnotificationid(filter): Observable<Notifications> {
    let url = API_URLS.getnotification + `/${filter.id}?flag=${filter.flag}`;

    // let url = API_URLS.getProgram+`/${id}?pageSize=8&pageNo=1`;
    return this.apiService.doGet(url, { loaderVisible: true })
  } 
  getnotificationRoles() {
    let recipientIds = [];
    let recipientArr = [];
    let urlRoles = API_URLS.notificationRolelist;
    return this.apiService.doGet(urlRoles, { loaderVisible: true });
  }

  getnotification(filter): Observable<Program[]> {
    let url = API_URLS.getnotification + `?`;
    let pagination = filter?.limit && filter?.page ? `PageNo=${filter?.page}&PageSize=${filter?.limit}` : ''
    let sort = filter?.sort && filter?.sort?.column ? `&SortBy.Column=${filter?.sort?.column}&SortBy.Order=${filter?.sort?.order}` : ''
    let searchText = filter.name ? `&searchText=${filter.name}` : '';
    url = url + pagination + sort + searchText;
    // let url = API_URLS.getProgram+`/${id}?pageSize=8&pageNo=1`;

    let req = {
      pageNo: filter?.page,
      pageSize: filter?.limit,
      sortBy: filter?.sort && filter?.sort?.column ? { Column: filter?.sort?.column, Order: filter?.sort?.order } : null,
      searchText: filter.name ?? '',
      filterBy: '',
      status: ''
    };

    return this.apiService.doPost(API_URLS.getnotification,req, { loaderVisible: true });
  }
  deleteProgram(req) {
    let url = API_URLS.deleteProgram + `/${req.id}`;
    return this.apiService.doPut(url, null, { loaderVisible: true, popupVisible: true, popupMessage: "Deleted Successfully" })
  }

  deleteNotification(req) {
    let url = API_URLS.deleteNotification + `/${req.notificationId}`;
    return this.apiService.doPut(url, null, { loaderVisible: true, popupVisible: true, popupMessage: "Deleted Successfully" })
  }
  updateManageReporting(req) {
    let url = API_URLS.updateManageReporting;
    return this.apiService.doPut(url, req, { loaderVisible: true, popupVisible: true, popupMessage: "Updated Successfully" })
  }
  createQuickSightDataSet(req) {
   // req.forEach((id) => {

    let url = API_URLS.createQuickSightDataset;
    return this.apiService.doPost(url,req, { loaderVisible: true })
    //})
  }
}

