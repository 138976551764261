import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ControlMessagesComponent } from './component/control-messages/control-messages.component';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { SuccessPopupComponent } from './component/success-popup/success-popup.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule
  ],
  declarations: [ControlMessagesComponent, SpinnerComponent, BreadcrumbComponent,ClickOutsideDirective, SuccessPopupComponent],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    ControlMessagesComponent,
    SpinnerComponent,
    BreadcrumbComponent,
    ClickOutsideDirective
  ]
})
export class SharedModule {}
