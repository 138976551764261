import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '@app/modules/home/service/permission.service';
import { UserInfoService } from '@app/modules/home/service/user-info.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProgramGuard implements CanActivate {
  constructor(private _uis: UserInfoService, private _ps: PermissionService, private _router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(res => {
      this._ps.getPermissions().subscribe((config) => {
        if (config?.roleId == 1 || this._ps.hasProgramViewPermission(config, route?.paramMap?.get('id'))) {
          res(true);
        } else {
          res(false);
          this._router.navigate(["/dashboard/not-found"])
        }
      },
        (error) => {
          console.log(error);
          res(false);
          this._router.navigate(["/dashboard/not-found"])
        }
      );
    });
  }
}
