import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PopupService } from '@app/shared/service/popup.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValidationService } from '../../service/validation.service';

@Component({
  selector: 'app-control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss']
})
export class ControlMessagesComponent implements OnDestroy, OnInit {
  subject$: Subject<any> = new Subject<any>();
  constructor(public popup: PopupService) { }
  ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }
  ngOnInit(): void {
    this.popup.isPopupVisible.pipe(takeUntil(this.subject$)).subscribe((res) => {
      setTimeout(() => {
        this.popup.resetMessage();
      }, 6000);
    })
  }

}
