import { Component, ChangeDetectionStrategy, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBreadcrumb } from '@app/data/schema/breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnChanges {
  @Input() dynamicNavItems: any[];
  navItems: Array<IBreadcrumb> = [];

  // If need to override some label dynamicNavItems is used
  constructor(private activatedRoute: ActivatedRoute) {
    // This will set default breadcrumb based on static configuration / dynamic if key exist
    this.updateBredcrumbs();
  }
  ngOnChanges() {
    this.updateBredcrumbs();
  }
  toLowercase(string) {
    return string?.toLowerCase();
  }
  
  // This funtion will get breadcrumb data from route params and reformat based on model
  updateBredcrumbs() {
    this.activatedRoute.data.subscribe(params => {
      this.navItems = [];
      params?.breadcrumb?.forEach(item => {
        const overrideItem = (this.dynamicNavItems || []).find((newitem) => newitem.name === item.name);        
        this.navItems.push({
          label: (overrideItem && overrideItem.label ? overrideItem.label : item.label),
          route: (overrideItem && overrideItem.route ? overrideItem.route : item.route),
          active: (overrideItem && overrideItem.hasOwnProperty('active')  ? overrideItem.active : item.active),
          visible: (overrideItem && overrideItem.hasOwnProperty('visible') ? overrideItem.visible : item.visible),
          params: item.params,
          terminal: (overrideItem && overrideItem.hasOwnProperty('terminal') ? overrideItem.terminal : item.terminal),
          name: item.name
        });
      });      
    });
  }
}
