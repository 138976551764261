<div class="errorContainer">
  <ng-container *ngFor="let item of popup.getMessage(); let idx=index">
    <div *ngIf="item?.type=='success' " class="alert alert-success errorPopup" role="alert">
      {{item?.message}}
      <span (click)="popup.hideMessage(idx)" aria-hidden="true">&times;</span>
    </div>
    <div *ngIf="item?.type=='danger' " class="alert alert-danger errorPopup" role="alert">
      {{item?.message}}
      <span (click)="popup.hideMessage(idx)" aria-hidden="true">&times;</span>
    </div>
    <div *ngIf="item?.type=='warning' " class="alert alert-warning errorPopup" role="alert">
      {{item?.message}}
      <span (click)="popup.hideMessage(idx)" aria-hidden="true">&times;</span>
    </div>
    <div *ngIf="item?.type=='info' " class="alert alert-info errorPopup" role="alert">
      {{item?.message}}
      <span (click)="popup.hideMessage(idx)" aria-hidden="true">&times;</span>
    </div>
  </ng-container>
</div>